function addUser(user) {
  const jsonUser = JSON.stringify(user);
  localStorage.setItem("user", jsonUser);
}

function getUser() {
  const jsonUser = localStorage.getItem("user");
  return jsonUser ? JSON.parse(jsonUser) : null;
}

function getImgProfileUser() {
  const jsonUser = localStorage.getItem("user");
  if (
    JSON.parse(jsonUser)?.imgs[0] &&
    JSON.parse(jsonUser)?.imgs[0].tipo === "perfil"
  )
    return JSON.parse(jsonUser)?.imgs[0];
  else {
    return {
      pathCompleto: require("../assets/img/brand/icon-fishandbird.png"),
    };
  }
}

function logoutUser() {
  localStorage.removeItem("user");
}

export { addUser, getUser, getImgProfileUser, logoutUser };
