// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import { addUser, getUser } from "services/user.services";
import TemplateDemo from "../../components/Page/UploadImg";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import axios from "axios";

const Profile = () => {
  const [images, setImages] = useState(getUser().imgs);
  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [cpfcnpj, setCpfCnpj] = useState("");
  const [bio, setBio] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [cadastur, setCadastur] = useState("");
  const [senha, setSenha] = useState("");
  const [confirmaSenha, setConfirmaSenha] = useState("");

  const handleChangeData = async (event) => {
    event.preventDefault();
    if (confirmaSenha !== senha) {
      toast.error("A senha deve ser igual a confirmação de senha");
      return;
    }

    let user = {
      nome: nome,
      sobrenome: sobrenome,
      cpfcnpj: cpfcnpj,
      bio: bio,
      email: email,
      telefone: telefone,
      cadastur: cadastur,
      senha: senha,
    };
    const changedFields = Object.keys(user).filter((key) => user[key] !== "");
    const changedData = {};
    changedFields.forEach((key) => {
      changedData[key] = user[key];
    });

    if (changedFields.length < 1) return;
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/usuarios/`,
        changedData
      );
      toast.success(`Dados alterados com sucesso.`);
    } catch (error) {
      if (error?.response?.status !== 200) {
        toast.error(`Erro: ${error.response.data.message}`);
      }
      console.error("Erro:", error);
    }
  };

  const handleAlteraImagePerfil = async (index, image) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/img/perfil/${image.id}`
      );
      getAllDataAndRefreshImg();
      toast.success(`Imagem de perfil alterada com sucesso!`);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error("Erro ao alterar imagem de perfil:", error);
    }
  };

  const handleDeleteImage = async (index, image) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/img/${image.id}`
      );
      getAllDataAndRefreshImg();
      toast.success(`Imagem deletada com sucesso!`);
    } catch (error) {
      console.error("Erro ao excluir imagem:", error);
    }
  };

  useEffect(() => {
    getAllDataAndRefreshImg();
  }, []);

  const getAllDataAndRefreshImg = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/usuarios/todos-meus-dados/`
      );
      addUser(response.data);
      setImages(getUser().imgs);
    } catch {}
  };

  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          {/* <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
              <Row className="justify-content-center">
                <Col className="order-lg-2" lg="3">
                  <div className="card-profile-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="rounded-circle"
                        src={getImgProfileUser().pathCompleto}
                      />
                    </a>
                  </div>
                </Col>
              </Row>
              <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                <div className="d-flex justify-content-between">
                  <Button
                    className="mr-4"
                    color="info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="sm"
                  >
                    Connect
                  </Button>
                  <Button
                    className="float-right"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="sm"
                  >
                    Message
                  </Button>
                </div>
              </CardHeader>
              <CardBody className="pt-0 pt-md-4 mt-4">
                <Row>
                  <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                      <div>
                        <span className="heading">22</span>
                        <span className="description">Friends</span>
                      </div>
                      <div>
                        <span className="heading">10</span>
                        <span className="description">Photos</span>
                      </div>
                      <div>
                        <span className="heading">89</span>
                        <span className="description">Comments</span>
                      </div>
                    </div>
                  </div>
                </Row>
                <div className="text-center">
                  <h3>
                    {getUser().nome} {getUser().sobrenome}
                    <span className="font-weight-light">, 27</span>
                  </h3>
                  <div className="h5 font-weight-300">
                    <i className="ni location_pin mr-2" />
                    Bucharest, Romania
                  </div>
                  <div className="h5 mt-4">
                    <i className="ni business_briefcase-24 mr-2" />
                    Solution Manager - Creative Tim Officer
                  </div>
                  <div>
                    <i className="ni education_hat mr-2" />
                    University of Computer Science
                  </div>
                  <hr className="my-4" />
                  <p>{getUser().bio}</p>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    Show more
                  </a>
                </div>
              </CardBody>
            </Card>
          </Col> */}
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <Form onSubmit={handleChangeData}>
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Minha conta</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      {/* <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      Settings
                    </Button> */}
                      <Button color="info" type="submit" className="ml-auto">
                        Salvar perfil
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <h6 className="heading-small text-muted mb-4">
                    Informações do usuário
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-nome"
                          >
                            Primeiro nome/Nome Fantasia
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={getUser().nome}
                            id="input-nome"
                            placeholder="Nome/Nome Fantasia"
                            type="text"
                            onChange={(e) => setNome(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Sobrenome
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={getUser().sobrenome}
                            id="input-last-name"
                            placeholder="Sobrenome"
                            type="text"
                            onChange={(e) => setSobrenome(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-senha"
                          >
                            Senha
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-senha"
                            placeholder="Senha"
                            type="password"
                            onChange={(e) => setSenha(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-confirmasenha"
                          >
                            Confirmação de senha
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-confirmasenha"
                            placeholder="Confirmação de senha"
                            type="password"
                            autoComplete="off"
                            onChange={(e) => setConfirmaSenha(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-cpf-cnpj"
                          >
                            CPF/CNPJ
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={getUser().cpfcnpj}
                            id="input-cpf-cnpj"
                            placeholder="000.000.000-00"
                            type="text"
                            onChange={(e) => setCpfCnpj(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {getUser().tipo === "pousada" && (
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-cadastur"
                            >
                              Cadastur
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={getUser().cadastur}
                              id="input-cadastur"
                              type="text"
                              onChange={(e) => setCadastur(e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Informações de contato
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            E-mail
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={getUser().email}
                            id="input-email"
                            placeholder="exemplo@aute.dev"
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-telefone"
                          >
                            Telefone
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={getUser().telefone}
                            id="input-telefone"
                            placeholder="66999999999"
                            type="phone"
                            onChange={(e) => setTelefone(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-address"
                          >
                            Address
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
                            id="input-address"
                            placeholder="Home Address"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-city"
                          >
                            City
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue="New York"
                            id="input-city"
                            placeholder="City"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            Country
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue="United States"
                            id="input-country"
                            placeholder="Country"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            Postal code
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-postal-code"
                            placeholder="Postal code"
                            type="number"
                          />
                        </FormGroup>
                      </Col>
                    </Row> */}
                  </div>
                  <hr className="my-4" />
                  {/* Description */}
                  <h6 className="heading-small text-muted mb-4">Sobre mim</h6>
                  <div className="pl-lg-4">
                    <FormGroup>
                      <label>Sobre mim</label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Escreva uma linda descrição para as outras pessoas saberem algo sobre você."
                        rows="4"
                        type="textarea"
                        defaultValue={getUser().bio}
                        onChange={(e) => setBio(e.target.value)}
                      />
                    </FormGroup>
                  </div>
                  {getUser().tipo === "pousada" && (
                    <div>
                      <hr className="my-4" />
                      {/* Description */}

                      <h6 className="heading-small text-muted mb-4">Fotos</h6>
                      <div className="pl-lg-4">
                        <FormGroup>
                          <label>Fotos de pousada</label>
                        </FormGroup>
                        <div className="row">
                          {images.map((image, index) => (
                            <div key={index} className="col-md-2 mb-3">
                              <div className="position-relative">
                                <img
                                  key={index}
                                  src={image.pathCompleto}
                                  alt=""
                                  className="img-fluid"
                                  style={{ width: "100%", height: "auto" }}
                                  // className="m-1 col-sm-6 col-md-2"
                                  // className=" col-sm-12 col-md-2"
                                />
                                <div
                                  className="icone-lixeira position-absolute top-0 end-0"
                                  onClick={() =>
                                    handleDeleteImage(index, image)
                                  }
                                  title="Excluir"
                                >
                                  <i
                                    className="fas fa-trash"
                                    title="Excluir"
                                    alt="Excluir"
                                  ></i>
                                </div>
                                <div
                                  className="icone-lixeira position-absolute top-0 end-0 icone-perfil"
                                  onClick={() =>
                                    handleAlteraImagePerfil(index, image)
                                  }
                                  title="Colocar para perfil"
                                >
                                  <i
                                    className={`fas fa-user ${
                                      image.tipo === "perfil"
                                        ? "text-yellow"
                                        : ""
                                    }`}
                                    title="Colocar para perfil"
                                    alt="Colocar para perfil"
                                  ></i>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <FormGroup>
                          <label>Carregar mais fotos</label>
                        </FormGroup>
                        <TemplateDemo
                          getAllDataAndRefreshImg={getAllDataAndRefreshImg}
                        />
                      </div>
                    </div>
                  )}
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
