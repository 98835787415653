// reactstrap components
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

const Register = () => {
  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [cpfcnpj, setCpfCnpj] = useState("");
  const [bio, setBio] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [senha, setSenha] = useState("");
  const [confirmaSenha, setConfirmaSenha] = useState("");
  const [isPousada, setIsPousada] = useState(true);
  const [classConfirmaSenha, setClassConfirmaSenha] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(true);
  let navigate = useNavigate();

  useEffect(() => {
    if (senha.length > 8 && senha === confirmaSenha) {
      setClassConfirmaSenha("has-success");
    }
  }, [confirmaSenha, senha]);

  const handleRegister = async (event) => {
    event.preventDefault();
    try {
      // Fazendo a solicitação POST para autenticar o usuário
      if (agreeTerms !== true) {
        toast.error("É necessário aceitar os termos");
      }
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/usuarios/`,
        {
          nome: nome,
          sobrenome: sobrenome,
          cpfcnpj: cpfcnpj,
          bio: bio,
          email: email,
          telefone: telefone,
          senha: senha,
          tipo: isPousada ? "pousada" : "cliente",
        }
      );
      toast.success(`Bem vindo ${response.data.nome}! Faça login.`);
    } catch (error) {
      if (error?.response?.status === 400) {
        toast.error(`Erro: ${error.response.data.message}`);
      } else if (error?.response?.status !== 201) {
        toast.warning(
          "Erro ao cadastrar, verifique o preenchimento do formulário."
        );
      }
      console.error("Erro:", error);
    }
  };

  return (
    <>
      <Col lg="6" md="8">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5 d-none">
            <div className="text-muted text-center mt-2 mb-4">
              <small>Cadastrar com</small>
            </div>
            <div className="text-center">
              <Button
                className="btn-neutral btn-icon mr-4"
                color="default"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="btn-inner--icon">
                  <img
                    alt="..."
                    src={
                      require("../../assets/img/icons/common/github.svg")
                        .default
                    }
                  />
                </span>
                <span className="btn-inner--text">Github</span>
              </Button>
              <Button
                className="btn-neutral btn-icon"
                color="default"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="btn-inner--icon">
                  <img
                    alt="..."
                    src={
                      require("../../assets/img/icons/common/google.svg")
                        .default
                    }
                  />
                </span>
                <span className="btn-inner--text">Google</span>
              </Button>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Cadastre-se com os seus dados abaixo:</small>
            </div>
            <Form role="form" onSubmit={handleRegister}>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Nome/Nome Fantasia"
                    type="text"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Sobrenome"
                    type="text"
                    value={sobrenome}
                    onChange={(e) => setSobrenome(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="E-mail"
                    type="email"
                    autoComplete="new-email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-id-card" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="CPF/CNPJ (opcional)"
                    type="text"
                    value={cpfcnpj}
                    onChange={(e) => setCpfCnpj(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-file-text" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Descrição do perfil"
                    type="text"
                    value={bio}
                    onChange={(e) => setBio(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-phone" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Telefone/Celular"
                    type="text"
                    value={telefone}
                    onChange={(e) => setTelefone(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Senha"
                    type="password"
                    autoComplete="new-password"
                    value={senha}
                    onChange={(e) => setSenha(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup className={classConfirmaSenha}>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Confirmação de senha"
                    type="password"
                    autoComplete="off"
                    value={confirmaSenha}
                    onChange={(e) => setConfirmaSenha(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-muted font-italic d-none">
                <small>
                  password strength:{" "}
                  <span className="text-success font-weight-700">strong</span>
                </small>
              </div>
              <Row className="my-4">
                <span className="clearfix" />
                <label className="custom-toggle">
                  <input
                    type="checkbox"
                    checked={isPousada}
                    onChange={(e) => setIsPousada(e.target.checked)}
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
                <label className="pl-2">É pousada?</label>
              </Row>
              <Row className="my-4">
                <Col xs="12">
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="agreeTerms"
                      type="checkbox"
                      checked={agreeTerms}
                      onChange={(e) => setAgreeTerms(e.target.checked)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="agreeTerms"
                    >
                      <span className="text-muted">
                        Eu aceito os{" "}
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          Termos e Condições de uso
                        </a>{" "}
                        e a{" "}
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          Política de Privacidade
                        </a>
                      </span>
                    </label>
                  </div>
                </Col>
              </Row>
              <div className="text-center">
                <Button className="mt-4" color="primary" type="submit">
                  Criar conta
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Register;
