import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import { FileUpload } from "primereact/fileupload";
import { ProgressBar } from "primereact/progressbar";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { Tag } from "primereact/tag";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primeicons/primeicons.css";
import axios from "axios";

export default function TemplateDemo({ getAllDataAndRefreshImg }) {
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef(null);

  const onTemplateSelect = (e) => {
    let _totalSize = totalSize;
    let files = e.files;

    Object.keys(files).forEach((key) => {
      _totalSize += files[key].size || 0;
    });

    setTotalSize(_totalSize);
  };

  const onTemplateUpload = async (e, option) => {
    fileUploadRef.current.clear();
    let _totalSize = 0;

    for (let file of e.files) {
      let formData = new FormData();
      formData.append("imagem", file);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/img/pousada`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        toast.success(`Foto enviada!`);
        getAllDataAndRefreshImg();
        // console.log("File uploaded successfully: ", response.data);
      } catch (error) {
        console.error("Error uploading file: ", error);
        toast.error(`Erro ao enviar foto!`);
      }
    }

    e.files.forEach((file) => {
      _totalSize += file.size || 0;
    });

    setTotalSize(_totalSize);
  };

  const onTemplateRemove = (file, callback) => {
    setTotalSize(totalSize - file.size);
    callback();
  };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    const value = totalSize / 100000;
    const formatedValue =
      fileUploadRef && fileUploadRef.current
        ? fileUploadRef.current.formatSize(totalSize)
        : "0 B";

    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "start",
        }}
      >
        {chooseButton}
        {uploadButton}
        {cancelButton}
        <div className="flex align-items-start gap-3 ml-auto">
          <span>{formatedValue} / 10 MB</span>
          <ProgressBar
            value={value}
            showValue={false}
            style={{ width: "10rem", height: "12px" }}
          ></ProgressBar>
        </div>
      </div>
    );
  };

  const itemTemplate = (file, props) => {
    return (
      <div>
        <div className="d-flex align-items-center col-12">
          <img
            alt={file.name}
            role="presentation"
            src={file.objectURL}
            width={100}
          />
          <span className="ml-3">
            {file.name}

            <Tag
              value={props.formatSize}
              severity="warning"
              className="px-3 py-2 ml-2"
            />
            {/* <small>{new Date().toLocaleDateString()}</small> */}
          </span>
          <Button
            type="button"
            icon="pi pi-times"
            className="p-button-outlined p-button-rounded p-button-danger ml-auto"
            onClick={() => onTemplateRemove(file, props.onRemove)}
          />
        </div>
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="d-flex align-items-center flex-column">
        <i
          className="pi pi-image mt-3 p-5"
          style={{
            fontSize: "5em",
            borderRadius: "50%",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
          }}
        ></i>
        <br />
        <br />
        <span
          style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
          className="mb-5"
        >
          Drag and Drop Image Here
        </span>
      </div>
    );
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-images",
    iconOnly: true,
    className: "custom-choose-btn p-button-rounded p-button-outlined",
  };
  const uploadOptions = {
    icon: "pi pi-fw pi-cloud-upload",
    iconOnly: true,
    className:
      " p-button-success custom-upload-btn p-button-rounded rounded-circle p-button-outlined",
  };
  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    iconOnly: true,
    className:
      "custom-cancel-btn p-button-danger p-button-rounded rounded-circle p-button-outlined",
  };

  return (
    <div>
      <Tooltip
        target=".custom-choose-btn"
        content="Escolher fotos"
        position="bottom"
      />
      <Tooltip target=".custom-upload-btn" content="Enviar" position="bottom" />
      <Tooltip target=".custom-cancel-btn" content="Limpar" position="bottom" />

      <FileUpload
        ref={fileUploadRef}
        name="image[]"
        // url="/api/upload"
        customUpload={true}
        uploadHandler={onTemplateUpload}
        multiple
        accept="image/*"
        maxFileSize={10000000}
        onUpload={onTemplateUpload}
        onSelect={onTemplateSelect}
        onError={onTemplateClear}
        onClear={onTemplateClear}
        headerTemplate={headerTemplate}
        itemTemplate={itemTemplate}
        emptyTemplate={emptyTemplate}
        chooseOptions={chooseOptions}
        uploadOptions={uploadOptions}
        cancelOptions={cancelOptions}
      />
    </div>
  );
}
