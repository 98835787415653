import axios from "axios";

// axios.defaults.withCredentials = "include";
axios.defaults.withCredentials = true;
// axios.defaults.crossDomain = true;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response &&
      error.response.status === 403 &&
      window.location.pathname !== "/auth/login"
    ) {
      window.location.pathname = "/auth/login";
    }
    return Promise.reject(error);
  }
);
