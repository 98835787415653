import axios from "axios";
import { logoutUser } from "./user.services";

async function logout() {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/api/auth/logout`,
    {}
  );

  if (response.status === 200) {
    logoutUser();
    window.location.href = "/auth/login";
  }
  return response;
}

export { logout };
